import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, Paper, Box, Button, Grid } from "@mui/material";
import Axios from "../../utils/axios";
import moment from "moment";
import { IoIosArrowBack } from "react-icons/io";
import Loader from "./Loader";

const CalendarContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const DateContainer = styled.div`
  box-shadow: 0px 0px 12.1px 0px #0000001c;
`;

const SlotContainer = styled.div`
  box-shadow: 0px 0px 12.1px 0px #0000001c;
`;

const DatePaper = styled(Paper)`
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#cfe8fc" : "inherit")};
  box-shadow: none;
  border-bottom: 1px solid #efefef;
  border-radius: 0px;
  &:hover {
    background-color: #ac6dfb;
    color: #ffffff;
  }
`;

const HeaderTypography = styled(Typography)`
  padding: 0;
  height: 40px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: "#333333"};
  font-weight: 800;
`;

const BackIcon = styled(IoIosArrowBack)`
  width: 15px;
  font-size: 15px;
  height: 15px;
  margin: 8px;
  margin-bottom: 0;
  color: "#333333"};
  cursor: pointer;
`;

const SlotButtonNew = styled(Button)`
  padding: 8px;
  width: 100%;
  color: #000;
  background-color: ${(props) => (props.slotAvailable ? "inherit" : "#eee")};
  display: ${(props) => (!props.slotAvailable ? "none" : "block")};
  &:hover {
    background-color: #ac6dfb;
    color: #fff;
    border-radius: 0;
  }
`;

const BotCal = ({ handleSendDailogApi, chatId, chatBotAdminData, stopSendloading, messages, adminid, apiBaseUrl,sessionId }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotsData, setSlotsData] = useState([]);
  const [dateLoad, setDateLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setDateLoad(true);
      try {
        const params = {
          userid: adminid,
          isAgency: true,
          department: messages?.slice(-1)[0]?.category,
          tZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sender: sessionId
        };

        const response = await Axios.get(`${apiBaseUrl}/todo/get-slots-availability`, {
          params,
        });

        if (response.data.data) {
          setDateLoad(false);
          const currentDateTime = moment(response.data.currentDateTime);
          const filteredSlotsData = response.data.data.map((dateObj) => {
            return {
              date: dateObj.date,
              slots: dateObj.slots
                .filter(
                  (slot) =>
                    moment(
                      `${dateObj.date} ${slot.endTime}`,
                      "MM/DD/YYYY hh:mm A"
                    ).isAfter(currentDateTime) &&
                    moment(
                      `${dateObj.date} ${slot.startTime}`,
                      "MM/DD/YYYY hh:mm A"
                    ).isAfter(currentDateTime)
                )
                .slice(),
            };
          });
          setSlotsData(filteredSlotsData);
        }
      } catch (error) {
        setDateLoad(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleSlotClick = (slot) => {
    if (slot.slotAvailable) {
      setSelectedSlot(slot);
    }
  };

  return (
    <>
      {!dateLoad ? (
        <CalendarContainer>
          <DateContainer hidden={selectedDate}>
            <Typography variant="p" sx={{ padding: 1, height: "25px", lineHeight: "30px", paddingBottom: "0", fontWeight: "700", marginTop: "4px" }}>
              Choose a date
            </Typography>
            <Box sx={{ overflowY: "auto", height: "250px" }}>
              {slotsData.map((dateObj) => (
                <DatePaper
                  key={dateObj.date}
                  onClick={() => handleDateClick(dateObj.date)}
                  selected={selectedDate === dateObj.date}

                >
                  <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "14px" }}>
                    <div>{moment(dateObj.date).format("dddd")}</div>
                    <div>{moment(dateObj.date).format("MMMM D, YYYY")}</div>
                  </Typography>
                </DatePaper>
              ))}
            </Box>
          </DateContainer>

          <SlotContainer hidden={!selectedDate}>
            <HeaderTypography >
              <span onClick={() => { setSelectedDate(null); setSelectedSlot(null); }}>
                <BackIcon />
              </span>
              {moment(selectedDate).format("dddd, MMMM D")}
            </HeaderTypography>

            {selectedDate && (
              <Box sx={{ overflowY: "auto", height: "250px", overflowX: "hidden" }}>
                <Grid container spacing={0} className="mt-2">
                  {slotsData.find((dateObj) => dateObj.date === selectedDate)?.slots.map((slot, idx) => (
                    <Grid
                      key={idx}
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{ borderRight: "1px solid #efefef", borderBottom: "1px solid #efefef", display: !slot.slotAvailable ? "none" : "" }}
                    >
                      <SlotButtonNew
                        onClick={() => {
                          if (!stopSendloading) {
                            handleSlotClick(slot);
                            handleSendDailogApi(moment(selectedDate + " " + slot.startTime).format(), chatId, true);
                          }
                        }}
                        disabled={stopSendloading}
                        slotAvailable={slot.slotAvailable}
                        hidden={!slot.slotAvailable}
                        sx={{ color: "black", width: "100%", }}
                      >
                        {slot.startTime}
                      </SlotButtonNew>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </SlotContainer>
        </CalendarContainer>
      ) : (
        <Loader size="medium" />
      )}
    </>
  );
};

export default BotCal;

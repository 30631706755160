import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Chatbot from "./Chatbot";
import styles from "./chatbot.module.css";
import { RxCross2 } from "react-icons/rx";
import { IoChatbubblesSharp } from "react-icons/io5";

const ChatBotIndex = ({ chatId, userid, userImage, connectToSocket, socketNewMessage, isChatClose, clearSocketState, adminid, name, apiBaseUrl }) => {
  const [isIconsetVisible, setIconsetVisible] = useState(false);

  const toggleIconset = () => {
    setIconsetVisible((prev) => !prev);
    clearSocketState();
  };

  return (
    <div style={{ zIndex: "999998", position: "fixed" }} className="App">
      <div className={styles.full_chatbot}>
        {isIconsetVisible && (
          <Chatbot
            name={name}
            chatId={chatId}
            userid={userid}
            userImage={userImage}
            onClose={toggleIconset}
            sessionId={uuidv4()}
            connectToSocket={connectToSocket}
            socketNewMessage={socketNewMessage}
            isChatClose={isChatClose}
            adminid={adminid}
            apiBaseUrl={apiBaseUrl}
          />
        )}
        <div className={styles.iconset} onClick={toggleIconset}>
          {isIconsetVisible && (
            <div className={styles.crossico}>
              <RxCross2 />
            </div>
          )}
          {!isIconsetVisible && (
            <div className={styles.showicon}>
              <IoChatbubblesSharp />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotIndex;

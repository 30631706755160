import { useState } from "react";
import Axios from "../../../utils/axios";
import { delayedExecution, isValidRating } from "../../../utils/validators";
import moment from "moment";

const useChatMessages = (sessionId, apiBaseUrl) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setchatLoading] = useState(false);
  const [hasChatCreated, setChatCreated] = useState(false);
  const [currentChatId, setCurrentChatId] = useState("");
  const [isBotWorking, setIsBotWorking] = useState(false);
  const [chatClose, setChatClose] = useState(false);
  const [ratingData, setRatingData] = useState(false);
  const [createChat, setCreateChat] = useState(false);
  const [createType, setcreateType] = useState("");
  const [chatBotAdminData, setchatBotAdminData] = useState();
  const [stopSendloading, setStopSendloading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const handleCloseChat = (chatId, onClose, handleClose) => {
    let conversationData = localStorage.getItem(chatId);
    if (conversationData && !chatClose) {
      Axios.post(`${apiBaseUrl}/botChat/closeChat`, {
        chatConversationId: JSON.parse(conversationData)._id,
        owner: false,
      }).then((res) => {
        setChatClose(true);
        let message = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: "",
        };
        setMessages([...messages, message]);
        handleClose();
      });
    } else {
      onClose();
    }
  };

  const ValidateKey = (chatId) => {
    setLoading(true);
    setIsBotWorking(true);
    if (localStorage.getItem(chatId)) {
      let conversationData = localStorage.getItem(chatId);
      Axios.get(`${apiBaseUrl}/botChat/getChatBotMessage?conversationId=${JSON.parse(conversationData)._id}`)
        .then((response) => {
          setLoading(false);
          if (response?.data?.data?.isActive) {
            setMessages(response?.data?.data?.chatMessages);
            setCreateChat(true);
            setchatLoading(false);
          } else {
            localStorage.removeItem(chatId);
            Axios.post(`${apiBaseUrl}/botChat/createChatbotFlow`, {
              encryptedKey: chatId,
              preview: true,
              sender: sessionId,
            }).then(async (res) => {
              setchatLoading(true);

              for (let index = 0; index < res?.data?.data?.length; index++) {
                const data = res?.data?.data[index];
                await delayedExecution();

                if (
                  data.nodeType === "greeting" ||
                  data.nodeType === "capture-user-details" ||
                  data.nodeType === "message"
                ) {
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                      sender: "bot",
                      type: data.nodeType,
                      message: data.nodeDesc,
                    },
                  ]);
                } else if (data.nodeType === "capture-location") {
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                      sender: "bot",
                      type: data.nodeType,
                      message: data.nodeDesc,
                    },
                  ]);
                  setIsAddress(true);
                } else if (data.nodeType === "question") {
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                      sender: "bot",
                      type: data.nodeType,
                      message: data.nodeDesc,
                      field: "dailog_select",
                      options: (data.options || []).map((data1) => {
                        return `${data1?.nodeDesc}`;
                      }),
                      value: "",
                    },
                  ]);
                }
              }
              setchatLoading(false);
            });
          }
        })
        .catch((error) => {
          setchatLoading(false);
          console.error("Error fetching bot messages:", error);
        });
    } else {
      Axios.post(`${apiBaseUrl}/botChat/createChatbotFlow`, {
        encryptedKey: chatId,
        sender: sessionId,
        preview: true,
      }).then(async (res) => {
        setchatLoading(true);
        setLoading(false);
        for (let index = 0; index < res?.data?.data?.length; index++) {
          const data = res?.data?.data[index];
          await delayedExecution();

          if (
            data.nodeType === "greeting" ||
            data.nodeType === "capture-user-details" ||
            data.nodeType === "message"
          ) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                type: data.nodeType,
                message: data.nodeDesc,
              },
            ]);
          } else if (data.nodeType === "capture-location") {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                type: data.nodeType,
                message: data.nodeDesc,
              },
            ]);
            setIsAddress(true);
          } else if (data.nodeType === "question") {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                type: data.nodeType,
                message: data.nodeDesc,
                field: "dailog_select",
                options: (data.options || []).map((data1) => {
                  return `${data1?.nodeDesc}`;
                }),
                value: "",
              },
            ]);
          }
        }
        setchatLoading(false);
      }).catch((err) => {
        setchatLoading(false);
      });;
    }

  };

  const handleSaveRating = (isFormAlreadySubmitted, rating) => {
    const ratingValue = rating === "good" ? 3 : rating === "average" ? 2 : 1;
    setchatLoading(true);
    const data = {
      rating: ratingValue,
      against: isFormAlreadySubmitted._id,
      user: isFormAlreadySubmitted.user_id,
      reviewFor: "chat bot",
    };

    Axios.post(`${apiBaseUrl}/review/addReview`, data).then((res) => {
      setRatingData(res.data.data);
      setchatLoading(false);
    }).catch((err) => {
      setchatLoading(false);
    });;
  };

  const handlepUpdateReview = (review, onClose) => {
    if (!review.trim()) {
      return;
    }

    let data = {
      reviewId: ratingData._id,
      comment: review,
    };
    setchatLoading(true);
    Axios.post(`${apiBaseUrl}/review/updateReview`, data).then((res) => {
      setIsBotWorking(false);
      setchatLoading(false);
      onClose();
    }).catch((err) => {
      setchatLoading(false);
    });;
  };

  const handleRating = (msg, setMsg, onClose, isFormAlreadySubmitted) => {
    if (!msg.trim()) {
      return;
    }
    if (messages[messages.length - 1].type === "rating") {
      if (isValidRating(msg)) {
        handleSaveRating(isFormAlreadySubmitted, msg.toLowerCase());
        setMessages([
          ...messages.slice(0, messages.length),
          {
            sender: "you",
            type: "message",
            message: msg,
          },
          {
            sender: "bot",
            type: "review",
            message: "Please provide your review about our services.",
          },
        ]);
      } else {
        setMessages([
          ...messages.slice(0, messages.length),
          {
            sender: "you",
            type: "message",
            message: msg,
          },
          {
            sender: "bot",
            type: "rating",
            options: ["Good", "Average", "Bad"],
            message: "You can only provide rating.",
            field: "dailog_select",
            value: "",
          },
        ]);
      }
      setMsg("");
    } else {
      handlepUpdateReview(msg, onClose);
      setMessages([
        ...messages.slice(0, messages.length),
        {
          sender: "you",
          type: "message",
          message: msg,
        },
        {
          sender: "bot",
          type: "review",
          message: "Thankyou for sharing your review.",
        },
      ]);
      setMsg("");
    }
  };

  const updateChat = (isFormAlreadySubmitted, msg) => {
    setMessages([
      ...messages,
      { sender: isFormAlreadySubmitted?._id, message: msg },
    ]);
  };

  const onChatMsgSubmit = (e, msg, chatId, isFormAlreadySubmitted, setMsg) => {
    if (!msg.trim()) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    setMsg("");
    setchatLoading(true);
    const data = {
      encryptedKey: chatId,
      chatId: isFormAlreadySubmitted._id,
      message: msg,
    };
    Axios.post(`${apiBaseUrl}/botChat/sendChatBotMessage`, data)
      .then((res) => {
        // Assuming the response contains the bot's message
        const botMessage = res.data.data.message;

        updateChat(isFormAlreadySubmitted, botMessage);
        setchatLoading(false);
      })
      .catch((err) => {
        setchatLoading(false);
      });
  };

  const handleSendDailogApi = (msg, chatId, time = false) => {
    if (!msg.trim()) {
      return;
    }

    setStopSendloading(true);
    setchatLoading(true);
    setIsAddress(false);
    setcreateType("");
    setMessages((prevMessages) => [
      ...prevMessages.slice(
        0,
        prevMessages.length -
        (prevMessages[prevMessages.length - 1].type === "question" ? 1 : 0)
      ),

      prevMessages[prevMessages.length - 1].type === "question"
        ? {
          ...prevMessages[prevMessages.length - 1],
          value: msg,
        }
        : {
          sender: "you",
          type: "message",
          field: "message",
          message: time ? moment(msg).format("MMMM Do [at] h:mm A") : msg,
        },
    ]);

    Axios.post(`${apiBaseUrl}/botChat/createChatbotFlow`, {
      encryptedKey: chatId,
      sender: sessionId,
      message: msg,
      preview: true,
    }).then(async (res) => {
      setStopSendloading(false);
      setchatLoading(true);

      for (let index = 0; index < res?.data?.data?.length; index++) {
        const data = res?.data?.data[index];
        await delayedExecution();
        if (
          data.nodeType === "greeting" ||
          data.nodeType === "capture-user-details" ||
          data.nodeType === "message"
        ) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              type: data.nodeType,
              message: data.nodeDesc,
            },
          ]);
        } else if (data.nodeType === "capture-location") {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              type: data.nodeType,
              message: data.nodeDesc,
            },
          ]);
          setIsAddress(true);
        } else if (data.nodeType === "question") {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              type: data.nodeType,
              message: data.nodeDesc,
              field: "dailog_select",
              options: data.options?.map((data1) => {
                return `${data1?.nodeDesc}`;
              }),
              value: "",
            },
          ]);
        } else if (data.nodeType === "live-chat") {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              type: data.nodeType,
              message: data.nodeDesc,
            },
          ]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              type: data.nodeType,
              message: data.nodeDesc,
              category: data.category,
            },
          ]);
          setcreateType(data.nodeType);
        }
      }

      if (res.data?.chatData?._id) {
        if (res.data?.chatData && res.data?.chatData?._id) {
          setCurrentChatId((prev) => res.data?.chatData?._id);
        }
        setCreateChat(true);
        localStorage.setItem(
          chatId,
          JSON.stringify({
            _id: res.data?.chatData._id,
            user_id: res.data?.chatData.chatUserMetaData,
          })
        );
        setchatLoading(false);
        setChatCreated((prev) => true);

        Axios.get(
          `${apiBaseUrl}/botChat/getChatBotMessage?conversationId=${res.data?.chatData?._id}`
        ).then((response) => {
          setMessages(response?.data?.data?.chatMessages);
          setchatLoading(false);
        });
      }
      setchatLoading(false);
      if (res.data.complete) {
        setcreateType("");
        setStopSendloading(false);
        if (!res.data?.chatData?._id) {
          setIsBotWorking(false);
          setChatClose(true);
        } else {
          setIsBotWorking(true);
          setChatClose(false);
        }
      }
    }).catch((err) => {
      setchatLoading(false);
    });
  };

  const handleEmojiSelect = (emoji, textareaRef, setMsg, handleClose) => {
    const cursorPos = textareaRef.current.selectionStart;

    setMsg((prevMsg) => {
      const start = prevMsg.substring(0, cursorPos);
      const end = prevMsg.substring(cursorPos);
      return `${start}${emoji.native}${end}`;
    });
    handleClose();
  };

  return {
    stopSendloading,
    createType,
    messages,
    setMessages,
    loading,
    setLoading,
    chatLoading,
    setchatLoading,
    isBotWorking,
    setIsBotWorking,
    ratingData,
    setRatingData,
    chatClose,
    setChatClose,
    handleCloseChat,
    ValidateKey,
    handleRating,
    handlepUpdateReview,
    handleSaveRating,
    handleEmojiSelect,
    onChatMsgSubmit,
    handleSendDailogApi,
    createChat,
    hasChatCreated,
    currentChatId,
    chatBotAdminData,
    isAddress,
  };
};
export default useChatMessages;

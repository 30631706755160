import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styled from "styled-components";

const LoaderContainer = styled.div`
  width: 100%;
  height: ${(props) =>
    props.size === "small" ? "40px" : props.size === "medium" ? "50px" : "180px"};
`;

const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => (props.color ? props.color : "#3874ff")};
  width: ${(props) =>
    props.size === "small" ? "20px" : props.size === "medium" ? "40px" : "100px"};
  height: ${(props) =>
    props.size === "small" ? "20px" : props.size === "medium" ? "40px" : "100px"};
`;

const Loader = ({ size, color }) => {
  return (
    <LoaderContainer size={size}>
      <StyledBox>
        <StyledCircularProgress size={size} color={color} />
      </StyledBox>
    </LoaderContainer>
  );
};

export default Loader;

import { useCallback, useState } from "react";
import Axios from "../../../utils/axios";
import { debounce } from "../../../utils/commonHelp";

const useAddress = (apiBaseUrl) => {
  const [placesData, setPlacesData] = useState([]);
  const [showList, setShowList] = useState(false);
  const [error, setError] = useState("");

  const fetchPlacesList = (place) => {
    if (place === "") {
      setShowList(false);
      setPlacesData([]);
      return 0;
    }
    Axios.get(`${apiBaseUrl}/places/address-autocomplete?text=${place}`)
      .then((res) => {
        setPlacesData(res?.data?.data);
        setShowList(true);
      })
      .catch((err) => {
        setPlacesData([]);
        setError(err);
      });
  };

  const handleLocationClick = (location, setMsg) => {
    setMsg(location.description);
    setPlacesData([]);
    setShowList(false);
  };

  const userTxtChangeDebounce = useCallback(
    debounce(
      (txtval, handleClick1) => fetchPlacesList(txtval, handleClick1),
      800
    ),
    []
  );

  const onChangeAddress = (e) => {
    userTxtChangeDebounce(e.target.value);
  };

  const handleSaveAddresswithpostCode = (address, setMsg) => {
    Axios.get(`${apiBaseUrl}/places/place-detail-by-address-id?place_id=${address?.place_id}`)
      .then((res) => {
        if (res?.data?.data?.address?.zip) {
          setMsg((prev) => address?.description + ", " + res?.data?.data?.address?.zip)
        }
        else {
          setMsg((prev) => address?.description)
        }
      })
      .catch((err) => {
        console.log("No address found.");
      });
  }

  return {
    fetchPlacesList,
    onChangeAddress,
    handleLocationClick,
    placesData,
    showList,
    error,
    setShowList,
    handleSaveAddresswithpostCode
  };
};
export default useAddress;

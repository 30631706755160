import React from "react";
import ChatBotIndex from "./components/Chatbot/index";
import useSocket from "./components/hooks/useSocket";
import styles from "./index.module.css";
import { getMainApiHost } from "./constants/constants";

const App = ({ bot_id, userid, user_image, adminid, name, app_host }) => {


  const [apiHost, apiBaseHost] = getMainApiHost(app_host)

  const { socket, connectSocket, connected, newSocketMessage,
    isChatClose, setChatClose, setSocketNewMessage, } = useSocket(apiHost);

  const connectToSocket = (chatBotConnectid) => {
    if (!connected) { connectSocket(chatBotConnectid, () => { }); }
  };

  const clearSocketState = () => {
    setChatClose(false);
    setSocketNewMessage(null);
  };

  return (
    <div className={styles.jinniBotBody}>
      <ChatBotIndex
        chatId={bot_id}
        userid={userid}
        name={name}
        userImage={user_image}
        connectToSocket={connectToSocket}
        socketNewMessage={newSocketMessage}
        isChatClose={isChatClose}
        clearSocketState={clearSocketState}
        adminid={adminid}
        apiBaseUrl={apiBaseHost}
      />
    </div>
  );
};

export default App;
